import React, { useState, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';


import { ArrowRight,  } from '@mui/icons-material'
import { Zoom } from '@mui/material'


const UnpaidToken = ({tokenDetails,businessDetails,AppAlert, socket, getCustomerTokenList}) =>{

    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        //pageStyle:"@page { size: 2.5in 2.5in; margin: 0; padding:0 }, @media print { @page {  size: 2.5in 2.5in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }"
        //pageStyle:"@media print { html, body { height: 2.5in; /* Use 100% here to support printing more than a single page*/ margin: 0 !important;padding: 0 !important;overflow: hidden;}}; @page { size: 2.5in 2.5in; margin: 0; }"
    });

    const [savingInProgress, setSavingStatus] = useState(false);


    return (

        <Zoom in={true} style={{transitionDelay : 500}} key={tokenDetails.id}>
            <div className="card mb-2" >
                <div className="card-body" >
                        <div ref={printComponentRef} className="p-3" >
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="d-flex justify-content-around">
                                        {businessDetails.logo ? <img src={process.env.REACT_APP_BASE_URL+"/uploads/images/"+businessDetails.logo} style={{maxHeight:70}} alt={businessDetails.title} /> : businessDetails.title} 
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="barcode">
                                        *C-{tokenDetails.customerId}*
                                    </div>
                                    <div class="float-end mt-2">
                                        <h6>Token # : {tokenDetails.tokenPrefix}-{tokenDetails.tokenNo}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                    <hr />
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>Name : </strong>
                                            </div>
                                            <div className="col-6">
                                                {tokenDetails.firstName} {tokenDetails.lastName}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>S/O, W/O, D/O : </strong>
                                            </div>
                                            <div className="col-6">
                                                {tokenDetails.careOf}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>Age : </strong>
                                            </div>
                                            <div className="col-6">
                                                {tokenDetails.age}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>Gender : </strong>
                                            </div>
                                            <div className="col-6">
                                                {tokenDetails.gender == 1 ? "Male" : "Female" }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Phone :</strong>
                                            </div>
                                            <div className="col-8">
                                                {tokenDetails.customerPhone}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Date : </strong>
                                            </div>
                                            <div className="col-8">
                                                {moment(tokenDetails.checkInDate).format("LL")}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Department : </strong>
                                            </div>
                                            <div className="col-8">
                                                {tokenDetails.queueTitle} <ArrowRight /> {tokenDetails.serviceTitle }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Address : </strong>
                                            </div>
                                            <div className="col-8">
                                                {tokenDetails.address }
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            
                        </div>
                </div>
                <div className="card-footer">
                    <span className="float-end me-2"><button type="button" onClick={handlePrint} className="btn btn-success" >Print token</button></span>
                </div>
            </div>
        </Zoom>
    )
}


export default UnpaidToken