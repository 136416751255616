import React from 'react'
import { Link } from 'react-router-dom'
import AppLayout from '../../../layout/components/AppLayout'

function OPDSlip() {
    return (
        <AppLayout>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-4">
                        <Link to="/token/generate" style={{textDecoration:"none"}} >
                            <div className="card">
                                <div className="card-body">
                                    <p>New Customer</p>
                                    <h3>Generate Token</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to="/invoice/generate" style={{textDecoration:"none"}} >
                            <div className="card">
                                <div className="card-body">
                                    <p>Customer Token</p>
                                    <h3>Receive Patment</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default OPDSlip