import React, { useState, useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';




import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import AppLayout from '../../../layout/components/AppLayout'
import kioskServices from '../../Kiosk/kioskServices';
import printSettingServices from '../printSettingServices';

function PrintSettingPage() {

    const [headerSection,setHeaderSection] = useState(null);
    const [leftSection,setLeftSection] = useState(null);
    const getSectionList = () => {
        printSettingServices.getBySection(["parchi_header","parchi_left"]).then(res=>{
            setHeaderSection(res.data.parchi_header);
            setLeftSection(res.data.parchi_left);
        }).catch(error=>{
        })
    }

    useEffect(()=>{
        
        getSectionList();
        
    },[])

    const [businessDetails,setBusinessDetails] = useState(null)
    useEffect(()=>{
        
            kioskServices.getPortalDetails(1).then(res=>{
                setBusinessDetails(res.data.businessDetails);
                console.log("business details",res.data.businessDetails);
            }).catch(error=>{
            })
        
    },[])

    const update = (payload) =>{
         printSettingServices.save(payload).then(res=>{
            console.log("saving status",res);
         })
    }

    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-md-12">
                        <h3>PrintsSettingPage</h3>
                    </div>
                </div>
                <hr />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        {businessDetails &&
                            <>
                                {businessDetails.logo ? <img src={process.env.REACT_APP_BASE_URL+"/uploads/images/"+businessDetails.logo} style={{width:"100%"}} alt={businessDetails.title} /> : businessDetails.title}
                            </>
                        }
                    </div>
                    <div className="col-md-10">
                        {/* <textarea onChange={(e)=>setHeaderHtml(e.target.value)}>{headerHtml}</textarea> */ }
                        
                        {headerSection && 
                            <CKEditor
                                editor={ InlineEditor }
                                config={ {} }
                                data={headerSection.html}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    //console.log( { event, editor, data } );
                                    //setHeaderHtml(data);
                                    headerSection.html = data;
                                    setHeaderSection(headerSection);
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                    let payload = {
                                        id:headerSection.id,
                                        html:headerSection.html,
                                    }
                                    update(payload);
                                } }
                            />
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        {leftSection && 
                            <CKEditor
                                editor={ InlineEditor }
                                config={ {} }
                                data={leftSection.html}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    //console.log( { event, editor, data } );
                                    //setHeaderHtml(data);
                                    leftSection.html = data;
                                    setLeftSection(leftSection);
                                } }
                                onBlur={ ( event, editor ) => {
                                    //console.log( 'Blur.', editor );
                                    let payload = {
                                        id:leftSection.id,
                                        html:leftSection.html,
                                    }
                                    update(payload);
                                } }
                            />
                        }
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default PrintSettingPage