import { ArrowRight } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import UserListPage from '../../../users/pages/UserListPage';
import userServices from '../../../users/userServices';
import newsServices from '../../News/newsServices';
import BottomAdsComponent from '../components/BottomAdsComponent';
import RightAdsComponent from '../components/RightAdsComponent';
import displayServices from '../displayServices';

export default function MiniDisplayComponent() {

    const businessId = 1;
    const [displayList,setDisplayList] = useState(null);

    const getDisplayList = useCallback(() => {
        displayServices.getCheckinDisplayList(businessId).then(res=>{
            setDisplayList(null);
            if(res.data.length > 0 ){
                setDisplayList(res.data)
            }
        })
      },[businessId],
    )

    /* Prepair Master DD*/

    const [masters,setMasters] = useState(null);
    const getUserDD = () => {
        userServices.getUserDD().then(res=>{
            setMasters({"users":res.data})

            getDisplayList();
        })
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return (
            <p>{label.workLocation} <ArrowRight/> {label.firstName+" "+label.lastName}</p>
        )
    }

    


    useEffect(()=>{
        getUserDD();
        const timer = setInterval(()=>{
            if (!document.hidden) {
                getDisplayList();
            } 
        },10000)
        return ()=> clearInterval(timer)
    },[])

    useEffect(()=>{
        //getNewsList();
    },[])

    const [selectedCounter,setSelectedCounter] = useState(null);

  return (
    <>
        

        {selectedCounter === null && 
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header bg-primary text-white">Select Counter</div>
                        <div className="card-body">
                        <div className="list-group">
                            {masters && masters['users'].map(user=>
                                <a href="javascript:void(0)" onClick={()=>setSelectedCounter(user)} className="list-group-item list-group-item-action">
                                <span className="text-primary">{user.firstName} {user.lastName} </span> <span className="bg-success p-2 text-white rounded">{user.workLocation}</span>
                                </a>
                            )}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {selectedCounter && 
            <div className="row">
                <div className={"col-md-12"}>
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center fw-bold bg-primary p-2 text-white">
                                <span style={{fontSize:40}}>{selectedCounter.workLocation}</span>
                            </div>
                        </div>
                        
                        <div className="col-12">
                                    {displayList && 
                                        displayList.map(token =>
                                            token.status === 2 && token.counterUserId == selectedCounter.value &&
                                                <div className="card">
                                                    
                                                    <div className="card-body">
                                                        <div className="row mt-3">
                                                            
                                                            {token.firstName !== "" && 
                                                            <div className="col-12 text-center " >
                                                                <span className="fw-bold" style={{fontSize:50}}>{token.firstName}</span>
                                                            </div>
                                                            }
                                                            
                                                            <div className="col-12 mt-5 text-center">
                                                                <span className="bg-success text-white p-3 rounded shadow" style={{fontSize:60}}>
                                                                    {token.tokenPrefix}-{token.tokenNo}
                                                                </span>
                                                            </div>
                                                            
                                                            

                                                        </div>
                                                    </div>
                                                </div>

                                        )
                                    }
                                
                        </div>
                    </div>
                </div>
                
                
            </div>
        }
        

        
        
    </>
  )
}
