import api from "../../api/api";

const getPortalDetails = (businessId) => {
    return new Promise((resolve, reject)=>{
        api.get("portal/"+businessId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getSlots = (id,selectedDate) =>{
    return new Promise((resolve, reject)=>{
        //let q = "?date="+JSON.stringify(selectedDate.format("YYYY-MM-DD")).replaceAll('"', '');
        api.get("appointment/slots/"+id+"/"+selectedDate).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getPortalQueueDetails = (queueId) => {
    return new Promise((resolve, reject)=>{
        api.get("portal/queue-details/"+queueId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getCheckInStatus = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("checkin/waiting-time/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}




const bookAppointment = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("portal/book-appointment",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const doCheckin = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const kioskCheckin = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("kiosk-checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const cancelCheckin = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("checkin/customer-cancel",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getCustomerTokenList = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("customer/token",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getCustomerTokenListByCustomerId = (customerId) => {
    return new Promise((resolve, reject)=>{
        api.get("customer/token/"+customerId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getCustomerDetails = (customerId) => {
    return new Promise((resolve, reject)=>{
        api.get("customer/details/"+customerId).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


/*  Email Wok */


const requestEmailOTP = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("auth/customer/request-email-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const resendEmailOtp = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("auth/customer/resend-email-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const verifyEmailOTP = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("auth/customer/verify-email-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



const emailAppointmentDetails = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("portal/email-appointment",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const emailCheckInDetails = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("portal/email-checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}



/*   SMS Work */

const requestSmsOTP = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("auth/customer/request-sms-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const resendSmsOtp = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("auth/customer/resend-sms-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const verifySmsOTP = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("auth/customer/verify-sms-otp",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const smsAppointmentDetails = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("portal/sms-appointment",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const smsCheckInDetails = (payload) => {
    return new Promise((resolve, reject)=>{
        api.post("portal/sms-checkin",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const kioskServices = {
    getPortalDetails,
    getPortalQueueDetails,
    
    getSlots,
    getCheckInStatus,

    bookAppointment,
    doCheckin,


    kioskCheckin,

    requestEmailOTP,
    resendEmailOtp,
    verifyEmailOTP,
    emailAppointmentDetails,
    emailCheckInDetails,


    requestSmsOTP,
    resendSmsOtp,
    verifySmsOTP,
    smsAppointmentDetails,
    smsCheckInDetails,
    
    getCustomerTokenList,
    getCustomerTokenListByCustomerId,
    getCustomerDetails,
    cancelCheckin,
}

export default kioskServices;