import React, { useEffect, useState } from 'react'
import AppLayout from '../../layout/components/AppLayout'
import FeesStructureForm from '../components/FeesStructureForm';

import feesStructureServices from '../feesStructureServices';

function FeesStructurePage() {

    const [feesStructureList,setFeesStructureList] = useState(null);
    const getFeesStructureList = () => {
        feesStructureServices.get().then(res=>{
            setFeesStructureList(res.data);
        })
    }

    useEffect(()=>{
        getFeesStructureList();
    },[])

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-9"><h1>Manage Fees </h1></div>
                    <div className="col-md-3 float-end"><FeesStructureForm refreshList={getFeesStructureList} /> </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Name</td>
                                    <td>Amount</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {feesStructureList && feesStructureList.map((feesStructure,i)=>
                                    <tr>
                                        <td>{i+1}</td>
                                        <td>{feesStructure.title}</td>
                                        <td>{feesStructure.amount}</td>
                                        <td><FeesStructureForm data={feesStructure} refreshList={getFeesStructureList} /></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default FeesStructurePage