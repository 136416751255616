import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LinearProgress, Slide, StepContent } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { Step, StepLabel, Stepper } from '@mui/material';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import OTPInput, { ResendOTP } from "otp-input-react";


import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import kioskServices from '../kioskServices';
import { SocketContext } from '../../../context/socket';
import { Boy, Girl } from '@mui/icons-material';

function KioskCheckinFormPage({AppAlert}) {
    let navigate = useNavigate();
    let {businessId, queueId,serviceId } = useParams();
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);

    const docTypeList = [
        {value: 1, label: "None"},
        {value: 2, label: "PAN"},
        {value: 3, label: "Driving Licence"},
        {value: 4, label: "Aadhar"},
        {value: 5, label: "VoterID"},
        {value: 6, label: "Passport Number"},
    ];

    const initialValues = {
        phone:"",
        firstName:"",
        careOf:"",
        gender:"",
        age:"",
        address:"",
        
        documentType : 1,
        documentId : "",

        businessId:businessId,
        queueId:queueId,
        serviceId:serviceId
    }

    const validationRule = Yup.object({
        phone:Yup.string().required("Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Invalid Phone Number')
        .max(10, 'Invalid Phone Number'),

        firstName:Yup.string().required("Required"),
        careOf:Yup.string().required("Required"),
        gender:Yup.string().required("Required"),
        age:Yup.string().required("Required"),
        address:Yup.string().required("Required"),
        

    });


    
    const socket = useContext(SocketContext);

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        kioskServices.kioskCheckin(values).then(res=>{

            //socket.emit("send_new_token",{message:"new token received"})

            AppAlert(res.msg);
            localStorage.setItem('customerToken',JSON.stringify(res.customerToken));
            navigate("/kiosk/token-details");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const handleGenderChange = (formik,value) =>{
        console.log(formik.setFieldValue("gender",value));
    }

    return (
        <AppLayout>
        {true && 
            <div className="container">
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div className="">
                        <div className="p-2">
                            <Formik
                                initialValues={formValues || initialValues}
                                validationSchema={validationRule}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                            >
                                {
                                    (formik) => {
                                        //console.log(formik.values);
                                        return (
                                            <Form>
                                                    
                                                        <div className="row p-2">
                                                            <div className="col-md-12"> 
                                                                
                                                                <div className="row mt-4">
                                                                    <div className="text-center">
                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                            label="Mobile Number*" name="phone" 
                                                                            className="col-md-12"
                                                                        />
                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                            label="Your Name" name="firstName"
                                                                            className="col-md-12 mt-4"
                                                                        />
                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                            label="S/O, W/O, D/O" name="careOf"
                                                                            className="col-md-12 mt-4"
                                                                        />

                                                                        <FormikControl control="input" formik={formik} type="number" 
                                                                            label="Age" name="age"
                                                                            className="col-md-12 mt-4"
                                                                        />

                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                            label="address" name="address"
                                                                            className="col-md-12 mt-4"
                                                                        />

                                                                        <div className="row">
                                                                            <FormikControl control="autoselect" formik={formik} 
                                                                                label="ID Type" name="documentType"  
                                                                                options={docTypeList} multiple={false} 
                                                                                className="col-md-4 mt-4 pe-1"
                                                                            />

                                                                            <FormikControl control="input" formik={formik} type="text"
                                                                                label="ID Number" name="documentId"
                                                                                className="col-md-8 mt-4 pe-1"
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-12 mt-4 text-center">
                                                                            <div class="d-flex justify-content-start">
                                                                                <span className={formik.errors["gender"] !== undefined && formik.touched["gender"]?"text-danger fw-bold pt-2":"fw-bold pt-2"}>Gender : </span>
                                                                                <button type="button" className={formik.values.gender==1 ? "btn btn-primary ms-2" : "btn btn-outline-primary ms-2"} onClick={()=>handleGenderChange(formik,1)} ><Boy/>Male</button>
                                                                                <button type="button" className={formik.values.gender==2 ? "btn btn-primary ms-2" : "btn btn-outline-primary ms-2"} onClick={()=>handleGenderChange(formik,2)} ><Girl/>Female</button>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                { formik.errors["gender"] !== undefined && formik.touched["gender"] && 
                                                                                    <span className="text-danger">{formik.errors["gender"]}</span>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        {  /* JSON.stringify(formik.values)  */ }
                                                        </div>
                
                                                    {!savingInProgress && 
                                                        <>
                                                            <div className="col-md-12">
                                                                    <button className="btn btn-success float-end" type="submit">Check In </button>
                                                            </div>
                                                        </>
                                                    }

                                                    {savingInProgress && <LinearProgress/>}

                                                    
                                                
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                        </div>
                    </div>
                </Slide>
            </div>
        }
        </AppLayout>
    )
}

export default withAlert(KioskCheckinFormPage)