import { ArrowRight } from '@mui/icons-material';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import userServices from '../../../users/userServices';
import newsServices from '../../News/newsServices';
import BottomAdsComponent from '../components/BottomAdsComponent';
import RightAdsComponent from '../components/RightAdsComponent';
import displayServices from '../displayServices';


import YouTube from 'react-youtube';
import AppAnnouncement from './AppAnnouncement';
import { SocketContext } from '../../../context/socket';

import { useSpeechSynthesis } from 'react-speech-kit';
import AnnounceToken from './AnnounceToken';

export default function DiasplayPage() {

    const businessId = 1;
    const [displayList,setDisplayList] = useState(null);

    const [portalDetails,setPortalDetails] = useState(null);

    const socket = useContext(SocketContext);

    const { voices } = useSpeechSynthesis();
    const [voiceIndex, setVoiceIndex] = useState(null)

    
    const getBusinessDetails = useCallback(()=>{
        displayServices.getPortalDetails(businessId).then(res=>{
            setPortalDetails(res.data.businessDetails)
        }).catch(error=>{
        })
    },[businessId]);
    useEffect(()=>{
        getBusinessDetails()
    },[]);



    useEffect(() => {
        //console.log(sessionStorage.getItem('voiceIndex'));
        let vi  = sessionStorage.getItem('voiceIndex')
        if(vi !== "null"){
            setVoiceIndex(vi);
        }
        /*
        const interval = setInterval(() => {
          window.location.reload()
        }, 10000);
        return () => clearInterval(interval);
        */

    }, []);

    
    let localJson = null;
    const getDisplayList = useCallback(() => {
        displayServices.getCheckinDisplayList(businessId).then(res=>{
            
            if(res.data.length > 0 ){
                if(JSON.stringify(localJson) !== JSON.stringify(res.data)){
                    localJson = res.data;
                    //console.log("new Json received")
                    setDisplayList(res.data)
                } else {
                    setDisplayList(res.data)
                    //console.log("same Json Received");
                }
            } else {
                setDisplayList([]);
            }
        })
      },[businessId],
    )

    /* Prepair Master DD*/
    const [masters,setMasters] = useState(null);
    const getUserDD = () => {
        userServices.getUserDD().then(res=>{
            setMasters({"users":res.data})
            getDisplayList();
        })
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return (
            <>{label.workLocation} <ArrowRight/> {label.firstName+" "+label.lastName}</>
        )
    }

    const getCounterNumber = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return label.workLocation
    }

    const [bottomNewsList,setBottomNewList] = useState(null);
    const [rightNewsList,setRightNewList] = useState(null);

    const getNewsList = () =>{
        displayServices.getNewsList().then(res=>{
            res.bottomAds.length > 0 ? setBottomNewList(res.bottomAds) : setBottomNewList(null);
            res.rightAds.length > 0 ? setRightNewList(res.rightAds) : setRightNewList(null);
        }).catch(error=>{
        })
    }


    const [announceTokenId,setAnnounceTokenId] = useState(0);
    
    // socket events
    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_announcement",(data)=>{
            getDisplayList();
            //console.log("announcement Received",data);
            setAnnounceTokenId(data.token.id)
        })
        return () => socket.off('receive_announcement');
    },[socket])

    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_new_token",(data)=>{
            getDisplayList();
            //console.log("new token Received",data);
            setAnnounceTokenId(0)
        })
        return () => socket.off('receive_new_token');
    },[socket])


    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_close_token",(data)=>{
            getDisplayList();
            //console.log("close received",data);
            setAnnounceTokenId(0)
        })
        return () => socket.off('receive_close_token');
    },[socket])

    useEffect(()=>{
        getUserDD();
        getNewsList();
    },[])



  return (
    <div className="container-fluid">
        {/*Header End  */}
        <div className="row shadow p-2 mb-3">
            <div className="col-12 text-center">
                {portalDetails && 
                    <Link className="navbar-brand" to="/auth/logout">
                        {portalDetails.logo ? <img src={process.env.REACT_APP_BASE_URL+"/uploads/images/"+portalDetails.logo} style={{maxHeight:70}} alt={portalDetails.title} /> : portalDetails.title} 
                    </Link>
                }
            </div>
        </div>
        {/*Header End  */}

        {voiceIndex === null  &&
            <div className="row">
                
                <div className="col-md-12">
                <label htmlFor="voice">Voice</label>
                <select
                id="voice"
                name="voice"
                value={voiceIndex || ''}
                onChange={(event) => {
                    setVoiceIndex(event.target.value);
                    sessionStorage.setItem("voiceIndex",event.target.value)
                }}
                >
                <option value="">-No Language Selected-</option>
                {voices.map((option, index) => (
                    <option key={option.voiceURI} value={index}>
                    {`${option.lang} - ${option.name}`}
                    </option>
                ))}
                </select>
                </div>
            </div>
        }
        

        <div className="row">
            <div className={rightNewsList !== null ? "col-md-8" : "col-md-12"}>
                <div className="row">
                    <div className="col-12">
                        {voiceIndex !== null &&
                        
                        <AnnounceToken displayList={displayList} getCounterNumber={getCounterNumber} announceTokenId={announceTokenId} voiceIndex={voiceIndex} />
                        }
                        
                        { /* <YouTube videoId="2g811Eo7K8U" opts={opts} onReady={onYouTubeReady} /> */ }
                    </div>
                    <div className="col-12">
                        <table className="table fw-bold" style={{fontSize:22}}>
                            <thead>
                                <tr className="table-dark">
                                    <th width="20%">Token #</th>
                                    <th width="35%">Visitor Name</th>
                                    {/*<th width="20%">Service</th> */}
                                    <th width="45%">Counter #</th>
                                    {/*<th width="20%">Status</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {displayList && 
                                    displayList.map(token =>
                                        token.status === 2 &&
                                            <tr key={token.id} className={token.status === 2 ? "bg-danger text-white":"" } style={{fontSize:24}}>
                                                <td className="p-1"><span className="blinking">{token.tokenPrefix}-{token.tokenNo}</span></td>
                                                <td className="p-1"><span className="blinking">{token.firstName}-{token.lastName}</span></td>
                                                {/*<td>{token.queueTitle}<ArrowRight/>{token.serviceTitle}</td> */}
                                                <td className="p-1">{masters && token.counterUserId && token.status ===2  ? 
                                                    <><span className="blinking">{getMasterLabel("users",token.counterUserId) } </span></>
                                                        : 
                                                    "Waiting"}
                                                    </td>
                                                {/* <td>{token.statusTitle}</td> */}
                                            </tr>
                                        
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="ms-1">Be Ready You Are Next</h3>
                    </div>
                    <div className="col-md-12">
                        {displayList && 
                            displayList.map(token =>
                            token.status !== 2 && token.waitingTime.eta === token.waitingTime.avgProcessTime &&
                                
                                
                                <div  key={token.id} className="m-1 card float-start blink-infinite">
                                    <div className="card-header bg-warning"> <h4>{token.tokenPrefix}-{token.tokenNo}</h4> </div>
                                    <h6 className="pt-2 text-center text-success"> ~ {token.waitingTime.eta} Min.</h6>
                                </div>
                                
                            )
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <h3 className="ms-1">Next in queue</h3>
                    </div>
                    <div className="col-md-12">
                        {displayList && 
                            displayList.map(token =>
                            token.status !== 2 && token.waitingTime.eta !== token.waitingTime.avgProcessTime &&
                                
                                
                                <div  key={token.id} className="m-1 card float-start">
                                    <div className="card-header bg-primary text-white"> <h4>{token.tokenPrefix}-{token.tokenNo}</h4> </div>
                                    <h6 className="pt-2 text-center">~ {token.waitingTime.eta} Min.</h6>
                                </div>
                                
                            )
                        }
                    </div>
                </div>

            </div>
            {rightNewsList &&
                <div className="col-md-4">
                    <RightAdsComponent newsList={rightNewsList} />
                </div>
            }
            
        </div>
        

        {bottomNewsList && portalDetails && <BottomAdsComponent newsList={bottomNewsList} portalTitle={portalDetails.title} /> }
        
    </div>
  )
}
