import api from "../../api/api";


const getTokenDetails = (barcode) => {
    return new Promise((resolve, reject)=>{
        api.get("barcode-print/get-token-details/"+barcode).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const barcodePrintServices = {
    getTokenDetails,
}

export default barcodePrintServices;