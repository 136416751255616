import { KeyboardArrowRight } from '@mui/icons-material';
import { Stepper,Step, StepLabel, StepContent, Slide } from '@mui/material';
import React, { useState,useEffect } from 'react'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout';
import kioskServices from '../../Kiosk/kioskServices';
import CustomerForm from '../components/CustomerForm';



const QueueListItem = ({queue,AppAlert,selectCustomerQueue}) =>{

    

    const [checkInStatus,setCheckInStatus] = useState(false);
    const [waitingTime,setWaitingTime] = useState(10);
    const getCheckinStatus = (queue =>{
        
        kioskServices.getCheckInStatus(queue.id).then(res=>{
            setCheckInStatus(true);
            setWaitingTime(res.waitingTime);
        }).catch(error=>{
            setCheckInStatus(false);
        })
    })

    useEffect(()=>{
        if(queue.queueType === 2){
            getCheckinStatus(queue);
        }
    },[])

    const selectQueue = () =>{
        if(queue.queueType === 2 ){
            if(checkInStatus) {
                selectCustomerQueue(queue);
            } else {
                AppAlert("Sorry we are closed","error")
            }
        }

        
        
    }

    return (
        <> 
            <button type="button" onClick={selectQueue} className="list-group-item list-group-item-action d-flex justify-content-between align-items-start p-3">
                <div className="ms-2 me-auto">
                <div className="fw-bold">{queue.title}</div>
                    {queue.queueType === 2 && 
                        <>
                            { checkInStatus && 
                                <span className="text-success">
                                    Waiting Time  {waitingTime} {waitingTime > 1 ? "minutes" : "minute" }
                                </span> }
                            { !checkInStatus && <span className="text-danger">Curretly Closed</span> }
                        </>
                    }
                </div>
                <span className=""><KeyboardArrowRight/></span>
            </button>
        </>
    )
}



function GenerateNewToken({AppAlert}) {

    /*  Stepper Work  Start*/
    const [activeStep,setActiveStep] = useState(0);
    const [selectedQueue,setSelectedQueue] = useState(null);
    const [selectedService,setSelectedService] = useState(null);

    const [portalDetails,setPortalDetails] = useState(null);
    const getBusinessDetails = ()=>{
            kioskServices.getPortalDetails(1).then(res=>{
            setPortalDetails(res.data)
            localStorage.setItem('businessDetails',JSON.stringify(res.data.businessDetails));
        }).catch(error=>{
        })
    }
    useEffect(()=>{
        getBusinessDetails()
    },[])

    const [queueDetails,setQueueDetails] = useState(null);
    const [loading,setLoadingStatus] = useState(false);

    const getQueueDetails = () =>{
        setLoadingStatus(true);
        if(selectedQueue !== null){
            
            kioskServices.getPortalQueueDetails(selectedQueue.id).then(res=>{
                setQueueDetails(res.data);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            }).finally(()=>{
                setLoadingStatus(false);
            })
        }
        
    }

    useEffect(()=>{
        getQueueDetails();
    },[selectedQueue])


    const selectQueue = (queue) =>{
        setSelectedQueue(queue);
        setActiveStep(1);
    }

    const selectService  = (service) =>{
        setSelectedService(service)
        setActiveStep(2);
    }
    

    return (
        <AppLayout>
            <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <h1>GenerateNewToken</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-8 offset-2">
                    <Stepper activeStep={activeStep} orientation="vertical" >
                            <Step>
                                 
                                    
                                        <StepLabel>{selectedQueue ? selectedQueue.title : "Select Queue"}</StepLabel>
                                        <StepContent>
                                            {activeStep === 0 &&
                                                <div className="row mt-4">
                                                    <div className="text-center">
                                                        { portalDetails && portalDetails.queueList && portalDetails.queueList.map(queue=>
                                                            <QueueListItem queue={queue} key={queue.id} AppAlert={AppAlert} selectCustomerQueue={selectQueue} />
                                                        )}
                                                        
                                                    </div>
                                                </div>
                                            }
                                        </StepContent>
                                    
                            </Step>

                            <Step>
                                <StepLabel>{selectedService ? selectedService.title : "Select Service"}</StepLabel>
                                    <StepContent>
                                        {activeStep === 1 && queueDetails &&
                                            <>

                                                <ol className="list-group list-group-numbered mt-2">
                                                    {
                                                        queueDetails.queueBusinessServices.map(service =>
                                                            <button type="button" onClick={()=>selectService(service) } key={service.id} className="list-group-item list-group-item-action d-flex justify-content-between align-items-start p-3">
                                                                <div className="ms-2 me-auto">
                                                                    <div className="fw-bold">{service.title}</div>
                                                                </div>
                                                                <span className=""><KeyboardArrowRight/></span>
                                                            </button>
                                                        )
                                                    }
                                                </ol>
                                            </>
                                        }
                                    </StepContent>
                            </Step>

                            <Step>
                                <StepLabel>Customer Details</StepLabel>
                                    <StepContent>
                                        {activeStep === 2 &&
                                            <CustomerForm queueId={selectedQueue.id} serviceId={selectedService.id} />
                                        }
                                    </StepContent>
                            </Step>

                    </Stepper>
                </div>
            </div>
            </div>
        </AppLayout>
        
    )
}

export default withAlert(GenerateNewToken);