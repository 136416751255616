import React, { useState,useEffect, useContext } from 'react'
import { SocketContext } from '../../../context/socket';
import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout'
import barcodePrintServices from '../barcodePrintServices';

import PrintToken from '../../Kiosk/pages/PrintToken';
import { Link } from 'react-router-dom';



function BarcodeScanpage({AppAlert}) {

    const socket = useContext(SocketContext)
    const [barcode,setBarcode] = useState("");


    const handleBarcodeChange = (e) => {
        setBarcode(e.target.value);
    }

    const handleBarcodeKeydown = (e) => {
        if (e.key === 'Enter') {
            console.log('do validate');
            getTokenDetails();
        }
    }

    const [tokenDetails,setTokenDetails] = useState(null);
    const getTokenDetails = () =>{
        barcodePrintServices.getTokenDetails(barcode).then(res=>{
            setTokenDetails(res.checkInDetails);
        }).catch(error=>{
            console.log(error);
        })
    }


    const [businessDetails,setBusinessDetails] = useState(null)
    useEffect(()=>{
        if (localStorage.getItem("businessDetails") !== null) {
            setBusinessDetails(JSON.parse(localStorage.businessDetails))
        }
    },[])

    
    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" value={barcode} onChange={handleBarcodeChange} onKeyDown={handleBarcodeKeydown} />
                            <label htmlFor="floatingInput">Barcode</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <Link to="/print/setting" className="btn btn-primary float-end"  >Print Setting</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                    { tokenDetails && tokenDetails.status === 1 ?

                            <PrintToken 
                                tokenDetails={tokenDetails} 
                                businessDetails={businessDetails} 
                                AppAlert={AppAlert} 
                                socket={socket} 
                                getCustomerTokenList={getTokenDetails}
                            />
                        :
                            <h1>No Details Found </h1>

                    }
                    </div>
                </div>
            </div>
        </AppLayout>
    
  )
}

export default withAlert(BarcodeScanpage);