
import React, { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import withAlert from '../../../hoc/withAlert'
import AppLayout from '../../../layout/components/AppLayout'
import kioskServices from '../kioskServices';

import { SocketContext } from '../../../context/socket'

import PrintToken from './PrintToken'
import UnpaidToken from './UnpaidToken'

const  KioskTokenDetailsPage = ({AppAlert})  => {
    let navigate = useNavigate();
    const [tokenDetailList,setTokenDetailList] = useState(null)
    const socket = useContext(SocketContext);
    


    const getCustomerTokenList = ()=>{
        let payload = {customerToken : JSON.parse(localStorage.customerToken)};
        kioskServices.getCustomerTokenList(payload).then(res=>{
            setTokenDetailList(null);
            if(res.data.length > 0){
                setTokenDetailList(res.data)
            }
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            
        })
    }   

    

    useEffect(()=>{
        if (localStorage.getItem("customerToken") !== null) {
            getCustomerTokenList();
        }
    },[])

    const [businessDetails,setBusinessDetails] = useState(null)
    useEffect(()=>{
        if (localStorage.getItem("businessDetails") !== null) {
            setBusinessDetails(JSON.parse(localStorage.businessDetails))
        }
    },[])

    
    
    return (
        <AppLayout>
            
                <div className="container">
                    {tokenDetailList && tokenDetailList.length > 0 &&
                        <>
                            {tokenDetailList.map((tokenDetails,index) => 
                                
                                    tokenDetails.status === 1 &&
                                    <>
                                    {/*<PrintToken key={index} tokenDetails={tokenDetails} businessDetails={businessDetails} AppAlert={AppAlert} socket={socket} getCustomerTokenList={getCustomerTokenList}  />*/}
                                    <UnpaidToken key={index} tokenDetails={tokenDetails} businessDetails={businessDetails} AppAlert={AppAlert} socket={socket} getCustomerTokenList={getCustomerTokenList}  />
                                    </>
                            )}

                            <div className="card p-2">
                                <Link className="btn btn-success" to="/kiosk/portal/1">Book Another Token</Link>
                            </div>
                        </>
                    }
                    {!tokenDetailList && 
                        <div className="card p-3 text-center">
                            <h6>No Token Booked</h6>
                            <Link className="btn btn-success" to="/kiosk/portal/1">Book My Token</Link>
                        </div>
                    }
                </div>
            
        </AppLayout>
    )
}

export default withAlert(KioskTokenDetailsPage);