import React,{useState, useEffect} from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import { Boy, Girl } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import kioskServices from '../../Kiosk/kioskServices';
import { useNavigate } from 'react-router';


function CustomerForm({queueId,serviceId}) {
    let navigate = useNavigate();
    const [savingInProgress, setSavingStatus] = useState(false);
    const [formValues, setFormValues] = useState(null);

    const docTypeList = [
        {value: 1, label: "None"},
        {value: 2, label: "PAN"},
        {value: 3, label: "Driving Licence"},
        {value: 4, label: "Aadhar"},
        {value: 5, label: "VoterID"},
        {value: 6, label: "Passport Number"},
    ];
    

    const initialValues = {
        phone:"",
        firstName:"",
        careOf:"",
        gender:"",
        age:"",
        address:"",

        documentType : 1,
        documentId : "",
        
        
        businessId:1,
        queueId:queueId,
        serviceId:serviceId
    }

    const validationRule = Yup.object({
        phone:Yup.string().required("Required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, 'Invalid Phone Number')
        .max(10, 'Invalid Phone Number'),

        firstName:Yup.string().required("Required"),
        careOf:Yup.string().required("Required"),
        gender:Yup.string().required("Required"),
        age:Yup.string().required("Required"),
        address:Yup.string().required("Required"),
        

    });

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        
        console.log(values);
        
        kioskServices.kioskCheckin(values).then(res=>{

            //localStorage.setItem('customerToken',JSON.stringify(res.customerToken));
            console.log(res);
            navigate("/invoice/generate/?customerId="+res.customerId);
        }).catch(error=>{
            //AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
        
    }

    const handleGenderChange = (formik,value) =>{
        formik.setFieldValue("gender",value);
    }


    const [customerId,setCustomerId] = useState("");
    const handleCustomerIdChange = (e) => {
        setCustomerId(e.target.value);
    }

    const handleCustomerIdKeydown = (e) => {
        if (e.key === 'Enter') {
            console.log('do validate');
            getCustomerDetails();
        }
    }

    const getCustomerDetails = () =>{
        kioskServices.getCustomerDetails(customerId).then(res=>{
            if(res.data){
                let data = res.data;
                let autoFill = {
                    phone:data.phone,
                    firstName:data.firstName,
                    careOf:data.careOf,
                    gender:data.gender,
                    age:data.age,
                    address:data.address,
                    businessId:1,
                    queueId:queueId,
                    serviceId:serviceId
                }
                setFormValues(autoFill);
            }
        })
    }

    return (
        <div className="row">
                    <div className="col-md-12">
                        <h3>Existing Customer </h3>
                    </div>
                    <div className="col-md-12">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" value={customerId} onChange={handleCustomerIdChange} onKeyDown={handleCustomerIdKeydown} />
                            <label htmlFor="floatingInput">Customer Id </label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <h3>New Case </h3>
                    </div>
                    
            
            <div className="col-md-12">
                        <Formik
                                initialValues={formValues || initialValues}
                                validationSchema={validationRule}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                            >
                                {
                                    (formik) => {
                                        //console.log(formik.values);
                                        return (
                                            <Form>
                                                    
                                                        <div className="row">
                                                            <div className="col-md-12"> 
                                                                
                                                                <div className="row">
                                                                    <div className="text-center">
                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                            label="Mobile Number*" name="phone" 
                                                                            className="col-md-4 pe-1"
                                                                        />
                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                            label="Name" name="firstName"
                                                                            className="col-md-4 pe-1"
                                                                        />
                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                            label="S/O, W/O, D/O" name="careOf"
                                                                            className="col-md-4 pe-1"
                                                                        />

                                                                        <FormikControl control="input" formik={formik} type="number" 
                                                                            label="Age" name="age"
                                                                            className="col-md-4 mt-4 pe-1"
                                                                        />

                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                            label="Address" name="address"
                                                                            className="col-md-8 mt-4 pe-1"
                                                                        />

                                                                        

                                                                        
                                                                        <div className="row">
                                                                            <FormikControl control="autoselect" formik={formik} 
                                                                                label="ID Type" name="documentType"  
                                                                                options={docTypeList} multiple={false} 
                                                                                className="col-md-4 mt-4 pe-1"
                                                                            />

                                                                            <FormikControl control="input" formik={formik} type="text"
                                                                                label="ID Number" name="documentId"
                                                                                className="col-md-8 mt-4 pe-1"
                                                                            />
                                                                        </div>
                                                                        

                                                                        
                                                                        
                                                                        

                                                                        

                                                                        <div className="col-md-12 mt-4 text-center">
                                                                            <div className="d-flex justify-content-start">
                                                                                <span className={formik.errors["gender"] !== undefined && formik.touched["gender"]?"text-danger fw-bold pt-2":"fw-bold pt-2"}>Gender : </span>
                                                                                <button type="button" className={formik.values.gender==1 ? "btn btn-primary ms-2" : "btn btn-outline-primary ms-2"} onClick={()=>handleGenderChange(formik,1)} ><Boy/>Male</button>
                                                                                <button type="button" className={formik.values.gender==2 ? "btn btn-primary ms-2" : "btn btn-outline-primary ms-2"} onClick={()=>handleGenderChange(formik,2)} ><Girl/>Female</button>
                                                                            </div>
                                                                            <div className="d-flex">
                                                                                { formik.errors["gender"] !== undefined && formik.touched["gender"] && 
                                                                                    <span className="text-danger">{formik.errors["gender"]}</span>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        {  /* JSON.stringify(formik.values)  */ }
                                                        </div>
                
                                                    {!savingInProgress && 
                                                        <>
                                                            <div className="col-md-12">
                                                                    <button className="btn btn-success float-end" type="submit">Check In </button>
                                                            </div>
                                                        </>
                                                    }

                                                    {savingInProgress && <LinearProgress/>}

                                                    
                                                
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
            </div>
        </div>
    )
}

export default CustomerForm