import React, { useEffect, useState } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';

import { CircularProgress } from '@mui/material';

import moment from 'moment';

function DateFilterComponent( {setFilter}) {

    const [searchingInProgress, setSearchingStatus] = useState(false);
    const initialValues = {
        startDate:moment().format(moment.HTML5_FMT.DATE),
        endDate:moment().format(moment.HTML5_FMT.DATE),
    }

    const onSubmit = (values, onSubmitProps) => {
        console.log("Searching ", values)
        setFilter(values);
    }

    const validationRule = Yup.object({
        startDate: Yup.string().required("Required"),
        endDate: Yup.string().required("Required")
    });

    useEffect(()=>{
        setFilter(initialValues);
    },[])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationRule}
            onSubmit={onSubmit}
            enableReinitialize validateOnMount
        >
            {
                (formik) => {
                    //console.log(formik.values);
                    return (
                        <Form>
                            <div className="row p-2">
                                 
                                        <>

                                        <FormikControl control="date" formik={formik} type="date" 
                                            label="Start Date" name="startDate"
                                            min={moment().subtract(45, 'day').format(moment.HTML5_FMT.DATE)}
                                            max={moment().subtract(0, 'day').format(moment.HTML5_FMT.DATE)}
                                            
                                            className="col-md-4 col-xs-12 me-2" 
                                        />

                                        <FormikControl control="date" formik={formik} type="date" 
                                            label="End Date" name="endDate" 
                                            className="col-md-4 col-xs-12" 
                                            min={moment().subtract(45, 'day').format(moment.HTML5_FMT.DATE)}
                                            max={moment().subtract(0, 'day').format(moment.HTML5_FMT.DATE)}
                                        />
                                        {/*
                                        <div className="col-md-3 col-xs-12 p-2" >
                                            <FormikControl control="autoselect" formik={formik} 
                                                label="Section" name="section"  
                                                options={masters['section']} multiple={false} 
                                            />
                                        </div>
                                        */}
                                        </>
                                
                                <div className="col-md-3 col-xs-12 p-2" >
                                {searchingInProgress ? 
                                    <CircularProgress/> 
                                    :
                                    <button className="btn btn-success" type="submit">Search</button>
                                }
                                </div>
                            </div>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default DateFilterComponent