import React ,{useState,useEffect, useContext} from 'react'
import { useSearchParams } from 'react-router-dom';
import AppLayout from '../../../layout/components/AppLayout'
import { useLocation } from 'react-router';
import kioskServices from '../../Kiosk/kioskServices';
import PrintToken from '../../Kiosk/pages/PrintToken';
import withAlert from '../../../hoc/withAlert';
import { SocketContext } from '../../../context/socket';
import { Link } from 'react-router-dom';
import ReceivePayment from '../components/ReceivePayment';
import feesStructureServices from '../../../master/feesStructureServices';

const useQuery = () => {
    const {search } =  useLocation()
    return React.useMemo(()=> new URLSearchParams(search), [search]);
}


function InvoicePage({AppAlert}) {

    const socket = useContext(SocketContext)

    const [feesStructureList,setFeesStructureList] = useState(null);
    const getFeesStructureList = () => {
        feesStructureServices.get().then(res=>{
            setFeesStructureList(res.data);
        })
    }

    useEffect(()=>{
        getFeesStructureList();
    },[])

    const [businessDetails,setBusinessDetails] = useState(null)
    useEffect(()=>{
        
            kioskServices.getPortalDetails(1).then(res=>{
                setBusinessDetails(res.data.businessDetails);
                console.log("business details",res.data.businessDetails);
            }).catch(error=>{
            })
        
    },[])

    const [customerTokenList, setCustomerTokenList ] = useState(null);
    const getCustomerTokenList = () => {
        kioskServices.getCustomerTokenListByCustomerId(customerId).then(res=>{
            setCustomerTokenList(res.data);
        })
    }


    const [customerId,setCustomerId] = useState("");
    let query = useQuery();

    useEffect(()=>{
        if(query.get("customerId")){
            setCustomerId(query.get("customerId"))
            kioskServices.getCustomerTokenListByCustomerId(query.get("customerId")).then(res=>{
                setCustomerTokenList(res.data);
            })
        }
    },[])


    

    const handleCustomerIdChange = (e) => {
        setCustomerId(e.target.value);
    }

    const handleCustomerIdKeydown = (e) => {
        if (e.key === 'Enter') {
            console.log('do validate');
            getCustomerTokenList()
        }
    }

    
    

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" value={customerId} onChange={handleCustomerIdChange} onKeyDown={handleCustomerIdKeydown} />
                            <label htmlFor="floatingInput">Customer Id </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        

                    {customerTokenList && customerTokenList.length > 0 &&
                        <>
                            {customerTokenList.map((tokenDetails,index) => 
                                    tokenDetails.status === 1 && tokenDetails.paymentStatus === 0 &&
                                    <ReceivePayment 
                                        key={index} 
                                        tokenDetails={tokenDetails} 
                                        businessDetails={businessDetails} 
                                        AppAlert={AppAlert} 
                                        socket={socket} 
                                        getCustomerTokenList={getCustomerTokenList}
                                        feesStructureList={feesStructureList}
                                    />
                            )}

                            {customerTokenList.map((tokenDetails,index) => 
                                    tokenDetails.status === 1 && tokenDetails.paymentStatus === 1 &&
                                    <PrintToken key={index} tokenDetails={tokenDetails} businessDetails={businessDetails} AppAlert={AppAlert} socket={socket} getCustomerTokenList={getCustomerTokenList}  />
                            )}
                        </>
                    }

                    {customerTokenList && customerTokenList.length === 0 &&
                        <h3>No Record available</h3>
                    }


                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(InvoicePage)