import React,{useState} from 'react'
import moment from 'moment'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import invoiveServices from '../invoiceServices';
import { CheckCircle } from '@mui/icons-material';

const  ReceivePayment = ({tokenDetails, feesStructureList, businessDetails,AppAlert, socket, getCustomerTokenList}) => {


    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);
    const [remark,setRemark] = useState("");
    const [selectedFeesStructure,setSlectedFeesStructure] = useState(null);


    const handleClose = () =>{
        setDialogStatus(false);
    }

    const selectFeesStructure = (feesStructure) =>{
        setSlectedFeesStructure(feesStructure)
    }


    const receivePayment = () => {
        if(selectFeesStructure) {
            let payload = {
                tokenId : tokenDetails.id,
                customerId : tokenDetails.customerId,
                feesId : selectedFeesStructure.id,
                amount : selectedFeesStructure.amount,
                remark :remark,
            }
            console.log(payload);
            setSavingStatus(true);
            invoiveServices.receivePayment(payload).then(res=>{
                setDialogStatus(false);
                getCustomerTokenList();
                socket.emit("send_new_token",{message:"new token received"});
                AppAlert("Payment Received","success");
            }).catch(error=>{
                console.log(error);
                AppAlert("Something went wrong");
            }).finally(()=>{
                setSavingStatus(false)
            })
        } else {
            AppAlert("Please Select a Fees")
        }
    }



    return (
        <>
        <div className="row p-2">
            <div className="col-md-3 border p-2">
                <h6>REG-{tokenDetails.id}</h6>
                <h6>Token # : {tokenDetails.tokenPrefix}-{tokenDetails.tokenNo}</h6>
            </div>
            <div className="col-md-3 border p-2">
                <h6>{tokenDetails.firstName} {tokenDetails.lastName} ({tokenDetails.age}) {tokenDetails.gender == 1 ? "Male" : "Female" }</h6>
                <h6> <strong> S/O, W/O, D/O : </strong> {tokenDetails.careOf}</h6>
            </div>
            <div className="col-md-3 border p-2">
                <h6>{tokenDetails.customerPhone}</h6>
                <h6>{moment(tokenDetails.checkInDate).format("LL")}</h6>
            </div>
            <div className="col-md-3 border p-2">
                <button type="button" className="btn btn-success" onClick={()=>setDialogStatus(true)}  > Receive payment</button>
            </div>
        </div>


        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle>Payment</DialogTitle>
            <DialogContent>
                <div className="row p-2">
                    <div className="col-md-12">
                        <table className="table">
                            <tbody>
                            {feesStructureList && feesStructureList.map(feesStructure=>
                                <tr key={feesStructure.id} >
                                    <td>{feesStructure.title}</td>
                                    <td>
                                        <button type="button" className=
                                            {
                                                selectedFeesStructure && selectedFeesStructure.id === feesStructure.id ?
                                                    "btn btn-success"
                                                :
                                                    "btn btn-dark"
                                            }
                                            onClick={()=>{selectFeesStructure(feesStructure)}}
                                        > 
                                            {feesStructure.amount} 
                                            {selectedFeesStructure && selectedFeesStructure.id === feesStructure.id &&
                                                <CheckCircle/>
                                            }
                                            
                                            </button>
                                        </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        
                    </div>
                    <div className="col-md-12">
                        <h3>Remark</h3>
                        <input type="text" className="form-control" value={remark} onChange={(e)=>setRemark(e.target.value)}></input>
                    </div>
                    <div className="col-md-12">
                        <span className="float-end">
                            <button className="btn btn-primary" onClick={receivePayment} >Submit</button>
                        </span>
                    </div>
                </div>
            </DialogContent>
            
        </Dialog>
        </>
    )
}

export default ReceivePayment