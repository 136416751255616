
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import AppLayout from '../../../layout/components/AppLayout'
import DateFilterComponent from '../components/DateFilterComponent';
import reportServices from '../reportServices';

function MyCollectionReportPage() {

    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
    });
    const [collectionList,setCollectionList] = useState(null);

    const [filter,setFilter] = useState(null);

    const getCollectionList = () =>{
        if(filter){
            reportServices.getMyCollectionReport(filter).then(res=>{
                setCollectionList(res.data);
            })
        }
    }

    useEffect(()=>{
        getCollectionList();
    },[filter])

    const doTotal = () =>{
     
        let  total = 0;
        if(collectionList){
            for(let i=0;i<collectionList.length;i++){
                total= total + parseInt(collectionList[i].amount);
            }
        }
        return total;
    }

    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h3>My Collection Report </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <DateFilterComponent setFilter={setFilter} />
                    </div>
                    <div className="col-md-3">
                        <button type="button" className="btn btn-primary" onClick={handlePrint} >Print</button>
                    </div>
                </div>
                <div className="row m-2"  ref={printComponentRef}>
                    <div className="col-md-12">
                        <table className="table" >
                            <thead>
                                <tr>
                                    <th>Invoice #</th>
                                    <th>Patient Name</th>
                                    <th>Payment For</th>
                                    <th>Date</th>
                                    <th>Received By</th>
                                    <th>Amount</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {collectionList && collectionList.map(collection=>
                                    <tr>
                                        <td>{collection.id}</td>
                                        <td>{collection.firstName}</td>
                                        <td>{collection.title}</td>
                                        <td>{moment(collection.created_at).format("LL")}</td>
                                        <td>{collection.receivedByFirstName} {collection.receivedBylastName}</td>
                                        <td>{collection.amount}</td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={5} > <span className="float-end"> Total</span> </th>
                                    <td>{doTotal()}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </AppLayout>
        
    )
}

export default MyCollectionReportPage