import React, { useCallback, useEffect, useState, useRef, useContext } from 'react'
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';
import ConfirmDialog from '../../../components/ConfirmDialog'

import { ArrowRight, CalendarMonth, Person, WorkOutlined } from '@mui/icons-material'
import { LinearProgress, Slide, Zoom } from '@mui/material'
import kioskServices from '../kioskServices';
import printSettingServices from '../../BarcodePrint/printSettingServices';


const PrintToken = ({tokenDetails,businessDetails,AppAlert, socket, getCustomerTokenList}) =>{

    const printComponentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        //pageStyle:"@page { size: 2.5in 2.5in; margin: 0; padding:0 }, @media print { @page {  size: 2.5in 2.5in margin: 0mm !important;  body,html{padding:0px;margin:0px;} } }"
        //pageStyle:"@media print { html, body { height: 2.5in; /* Use 100% here to support printing more than a single page*/ margin: 0 !important;padding: 0 !important;overflow: hidden;}}; @page { size: 2.5in 2.5in; margin: 0; }"
    });

    const [savingInProgress, setSavingStatus] = useState(false);
    
    const confirmCancel = (tokenHash) =>{
        setSavingStatus(true);
        kioskServices.cancelCheckin({tokenHash:tokenHash}).then(res=>{
            AppAlert("Success");
            getCustomerTokenList();
            socket.emit("send_close_token",{message:"token cancelled"})
            //localStorage.removeItem("tokenDetails");
            setTimeout(()=>{
                //navigate("/customer-portal/1")
            },2000)
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }


    const confirmCancel1 = (tokenHash) =>{
       /* setSavingStatus(true);
        portalServices.cancelCheckin({tokenHash:tokenHash}).then(res=>{
            AppAlert("Success");
            socket.emit("send_close_token",{message:"token cancelled"})
            getCustomerTokenList();
            //localStorage.removeItem("tokenDetails");
            setTimeout(()=>{
                //navigate("/customer-portal/1")
            },2000)
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
        */
    }

    const [headerSection,setHeaderSection] = useState(null);
    const [leftSection,setLeftSection] = useState(null);
    const getPrintSectionList = () => {
        printSettingServices.getBySection(["parchi_header","parchi_left"]).then(res=>{
            setHeaderSection(res.data.parchi_header);
            setLeftSection(res.data.parchi_left);
        }).catch(error=>{
        })
    }
    useEffect(()=>{
        getPrintSectionList();
    },[])


    return (

        <Zoom in={true} style={{transitionDelay : 500}} key={tokenDetails.id}>
            <div className="card mb-2" >
                <div className="card-body" >
                        <div ref={printComponentRef} className="p-3" >
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="d-flex justify-content-around">
                                        {businessDetails.logo ? <img src={process.env.REACT_APP_BASE_URL+"/uploads/images/"+businessDetails.logo} style={{maxHeight:70}} alt={businessDetails.title} /> : businessDetails.title} 
                                        <div class="text-center">
                                        {headerSection && <div dangerouslySetInnerHTML={{ __html: headerSection.html }} /> }
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div class="barcode">
                                        *C-{tokenDetails.customerId}*
                                    </div>
                                    <div class="float-end mt-2">
                                        <h6>Token # : {tokenDetails.tokenPrefix}-{tokenDetails.tokenNo}</h6>
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className="row mt-2">
                                 <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <h4><strong>Token No : </strong></h4>
                                        </div>
                                        <div className="col-6">
                                            <h4>{tokenDetails.tokenPrefix}-{tokenDetails.tokenNo}</h4>
                                        </div>
                                        <div class="col-12">
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-12">
                                            
                                        </div>
                                        <div className="col-12">
                                            <div className="float-end">
                                                <strong>Phone No : </strong> <span>{businessDetails.businessPhone}</span> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="row">
                                    <hr />
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>Name : </strong>
                                            </div>
                                            <div className="col-6">
                                                {tokenDetails.firstName} {tokenDetails.lastName}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>S/O, W/O, D/O : </strong>
                                            </div>
                                            <div className="col-6">
                                                {tokenDetails.careOf}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>Age : </strong>
                                            </div>
                                            <div className="col-6">
                                                {tokenDetails.age}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <strong>Gender : </strong>
                                            </div>
                                            <div className="col-6">
                                                {tokenDetails.gender == 1 ? "Male" : "Female" }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Phone :</strong>
                                            </div>
                                            <div className="col-8">
                                                {tokenDetails.customerPhone}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Date : </strong>
                                            </div>
                                            <div className="col-8">
                                                {moment(tokenDetails.checkInDate).format("LL")}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Department : </strong>
                                            </div>
                                            <div className="col-8">
                                                {tokenDetails.queueTitle} <ArrowRight /> {tokenDetails.serviceTitle }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>Address : </strong>
                                            </div>
                                            <div className="col-8">
                                                {tokenDetails.address }
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                            </div>
                            {leftSection &&
                                <div className="row">
                                    <div class="col-md-4">
                                        <div className="p-2 border border-dark leftSection">
                                        
                                            <div dangerouslySetInnerHTML={{ __html: leftSection.html }} /> 
                                        
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <span className="float-end">DIAGNOSIS</span>
                                    </div>
                                </div>
                            }
                        </div>
                </div>
                <div className="card-footer">
                    {!savingInProgress &&
                        <>
                        {/* <span className="float-end"><ConfirmDialog title="Cancel My Token" id={tokenDetails.tokenHash} confirm={confirmCancel} >Are you Sure you want to cancel your token</ConfirmDialog></span> */}
                        <span className="float-end me-2"><button type="button" onClick={handlePrint} className="btn btn-success" >Print token</button></span>
                        </>
                    }
                    {savingInProgress && <LinearProgress/>}
                </div>
            </div>
        </Zoom>
    )
}


export default PrintToken